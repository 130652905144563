import React from "react";
import Navbar from "../../CommonComponents/Navbar/Navbar";
import Image from "../../CommonComponents/Image/Image";
import "./AboutUsPage.css";
import Text from "../../CommonComponents/Text/Text";
import Footer from "../../CommonComponents/Footer/Footer";
import { Helmet } from 'react-helmet';

export default function AboutUsPage() {
  return (
    <div className="AboutUsPageParent">
      {/* TITLE OF THE PAGE  */}
      <Helmet>
        <title>About Al Ali</title>
      </Helmet>

      {/* NAVBAR COMPONENT */}
      <Navbar />

      {/* PAGE BANNER */}

      <div className="AboutPageBanner">
        <div className="PageBannerWrapper">
          <Image
            Src={"./Images/PageBanners/about_topBAnner.webp"}
            Width={"100%"}
          />
        </div>
        <div className="ProductBannerBG"></div>
        {/* PAGE tab bg */}

        <div className="Leave1">
          <Image Src={"./Images/ProductsPage/ProductPageLeave1.png"} />
        </div>
      </div>

      {/* WHY CHOOSE US  */}
      <div className="WhyChoseUSParent">
        <div className="WhyChoseUS">
          <h1 className="text-center">
            About <span>Al-Ali</span>
          </h1>
          <div className="WhyChooseUsParg">
            <Text
              text={
                "With a rich heritage spanning eight decades, AL-ALI has emerged as a trusted name in cooking oil. We're not just a brand; we're a commitment to quality, health, and flavor. "
              }
            />
            <Text
              Margin={"35px 0px 35px 0px"}
              text={
                "Our cooking oil is cholesterol-free and vitamin-rich, ensuring every meal you prepare is a testament to the well-being and taste of your family. Like our legacy, AL-ALI is deeply rooted in communities, supporting local farmers and enriching lives."
              }
            />
            <Text
              text={
                "Join us on a journey where tradition meets innovation, and every drop of AL-ALI cooking oil transforms ordinary meals into culinary masterpieces."
              }
            />
          </div>
        </div>
      </div>

      {/* PAGE BANNER */}

      <div className="AboutPageBanner2">
        <div className="PageBannerWrapper">
          <Image
            Src={"./Images/PageBanners/AboutPAgeBAnner2.webp"}
            Width={"100%"}
          />
        </div>
        <div className="ProductBannerBG"></div>
        {/* PAGE tab bg */}

        <div className="Leave1"></div>
        <div className="AboutPageBanner2BG"></div>
      </div>

      {/* OUR MISSION  */}
      <div className="WhyChoseUSParent" id="mission">
        <div className="WhyChoseUS">
          <h1 className="text-center">
            Our <span>Commitment</span>
          </h1>
          <div className="WhyChooseUsParg">
            <Text
              text={
                "At AL-ALI, excellence is our promise. From quality and innovation to health and community, we're committed to delivering the best. Our commitment is your assurance of exceptional products and responsible practices. "
              }
            />
          </div>
        </div>
      </div>

      {/* FOOTER COMPONENT */}
      <Footer />
    </div>
  );
}
