import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import ProductsPage from './Pages/ProductsPage/ProductsPage';
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage';
import ContactUsPage from './Pages/ContactUsPage/ContactUsPage';
import ScrollToTop from './CommonComponents/ScrolToTop/ScrolToTop';

function App() {
  return (
    <div className="App">
    
    <BrowserRouter>
    <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} /> 
          <Route path="/about_us" element={<AboutUsPage />} /> 
          <Route path="/contact_us" element={<ContactUsPage />} />
          </Routes>
          </BrowserRouter>

    </div>
  );
}

export default App;
