import React, { useContext, useState } from "react";
import Navbar from "../../CommonComponents/Navbar/Navbar";
import { Tab, Tabs } from "react-bootstrap";
import "./ProductsPage.css";
import Image from "../../CommonComponents/Image/Image";
import Text from "../../CommonComponents/Text/Text";
import Footer from "../../CommonComponents/Footer/Footer";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../GlobalContext/Global.context";

export default function ProductsPage() {
  const { activeTab, setActiveTab } = useContext(GlobalContext);
  const [rowsToShow, setRowsToShow] = useState(4);
  const [rowsToShowPalmOil, setRowsToShowPalmOil] = useState(4);
  const [rowsToShowCanolaOil, setRowsToShowCanolaOil] = useState(4);
  const [rowsToShowGhee, setRowsToShowGhee] = useState(4);

  const handleToggleRows = () => {
    setRowsToShow((prevRows) => (prevRows === 4 ? SunFlowerOilData.length : 4));
    setRowsToShowPalmOil((prevRows) =>
      prevRows === 4 ? PalmOilData.length : 4
    );
    setRowsToShowCanolaOil((prevRows) =>
      prevRows === 4 ? CanolaOilData.length : 4
    );
    setRowsToShowGhee((prevRows) =>
    prevRows === 4 ? VegetableGheeData.length : 4
  );
  };
  const buttonTextSun = rowsToShow === 4 ? "Load More" : "Show Less";
  const buttonTextPalm = rowsToShowPalmOil === 4 ? "Load More" : "Show Less";
  const buttonTextCanola =
    rowsToShowCanolaOil === 4 ? "Load More" : "Show Less";
    const buttonTextGhee =
    rowsToShowGhee === 4 ? "Load More" : "Show Less";
    

  const handleTabSelect = (tabKey) => {
    // Update the activeTab state here
    setActiveTab(tabKey);
  };

  // Your array of objects for Palm Oil
  const PalmOilData = [
    {
      PackingName: "6x1.5 ltr HDPE Bottle",
      CartonPer20: 1575,
      CartonPer40: 3000,
    },
    {
      PackingName: "6x1.8 ltr HDPE Bottle",
      CartonPer20: 1364,
      CartonPer40: 2568,
    },
    {
      PackingName: "6x2 ltr HDPE Bottle",
      CartonPer20: 1364,
      CartonPer40: 2312,
    },
    {
      PackingName: "6x3 ltr HDPE Bottle",
      CartonPer20: 1060,
      CartonPer40: 1542,
    },
    { PackingName: "4x4 ltr HDPE Bottle", CartonPer20: 980, CartonPer40: 1734 },
    {
      PackingName: "4x4 ltr HDPE Bottle/TPH",
      CartonPer20: 1200,
      CartonPer40: 1734,
    },
    { PackingName: "4x5 ltr HDPE Bottle", CartonPer20: 910, CartonPer40: 1386 },
    {
      PackingName: "4x5 ltr HDPE Bottle/TPH",
      CartonPer20: 1050,
      CartonPer40: 1386,
    },
    { PackingName: "1X8 ltr Jerry Can", CartonPer20: 2600, CartonPer40: 3468 },
    { PackingName: "1X10 ltr Jerry Can", CartonPer20: 2100, CartonPer40: 2774 },
    { PackingName: "1X10 ltr Tin", CartonPer20: 2100, CartonPer40: 2744 },
    { PackingName: "1X16 ltr Jerry Can", CartonPer20: 1320, CartonPer40: 1734 },
    { PackingName: "1X16 ltr Tin", CartonPer20: 1448, CartonPer40: 1734 },
    { PackingName: "1X18 ltr Jerry Can", CartonPer20: 1320, CartonPer40: 1540 },
    { PackingName: "1X18 ltr Tin", CartonPer20: 1242, CartonPer40: 1540 },
    { PackingName: "1X20 ltr Jerry Can", CartonPer20: 1300, CartonPer40: 1388 },
  ];

  // Your array of objects for CanolaOilData
  const CanolaOilData = [
    {
      PackingName: "6x1.5 ltr Pet Bottle",
      CartonPer20: 1575,
      CartonPer40: 3000,
    },
    {
      PackingName: "6x1.8 ltr Pet Bottle",
      CartonPer20: 1176,
      CartonPer40: 2542,
    },
    { PackingName: "6x2 ltr Pet Bottle", CartonPer20: 1176, CartonPer40: 2270 },
    { PackingName: "6x3 ltr Pet Bottle", CartonPer20: 980, CartonPer40: 1574 },
    { PackingName: "4x5 ltr Pet Bottle", CartonPer20: 936, CartonPer40: 1362 },
    {
      PackingName: "1x10 ltr Pet Bottle",
      CartonPer20: 2200,
      CartonPer40: 2726,
    },
    {
      PackingName: "1x20 ltr Pet Bottle",
      CartonPer20: 1300,
      CartonPer40: 1362,
    },
  ];

  // Your array of objects for VegetableGheeData
  const VegetableGheeData = [
    { PackingName: "500 g Tin" },
    { PackingName: "1 kg Tin" },
    { PackingName: "2 kg Tin" },
    { PackingName: "4 kg Tin" },
    { PackingName: "8 kg Tin" },
    { PackingName: "16 kg Tin" },

  ];

  // Your array of objects for SunFlowerOilData
  const SunFlowerOilData = [
    {
      PackingName: "6x1.5 ltr Pet Bottle",
      CartonPer20: 1575,
      CartonPer40: 3000,
    },
    {
      PackingName: "6x1.8 ltr Pet Bottle",
      CartonPer20: 1176,
      CartonPer40: 2542,
    },
    { PackingName: "6x2 ltr Pet Bottle", CartonPer20: 1176, CartonPer40: 2270 },

    { PackingName: "6x3 ltr Pet Bottle", CartonPer20: 980, CartonPer40: 1574 },
    { PackingName: "4x5 ltr Pet Bottle", CartonPer20: 936, CartonPer40: 1362 },
    {
      PackingName: "1x10 ltr Pet Bottle",
      CartonPer20: 2200,
      CartonPer40: 2726,
    },
    {
      PackingName: "1x20 ltr Pet Bottle",
      CartonPer20: 1300,
      CartonPer40: 1362,
    },
  ];

  return (
    <div className="ProductsPageParent">
      {/* TITLE OF THE PAGE  */}
      <Helmet>
        <title>Al Ali Products</title>
      </Helmet>

      {/* NAVBAR COMPONENT */}
      <Navbar />
      <div className="ProductPageTabs">
        {/* PRODUCT TABS SECTION */}
        <div className="OurProductTabs" id="products">
          <Tabs
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            id="my-tabs"
            onSelect={handleTabSelect}
          >
            {/* Sunflower OIL */}

            <Tab eventKey="sunFlowerOil" title={"Sunflower Oil"}>
              <div className="TabsContent d-flex">
                <div className="TabContentLeft">
                  <Image Src={"./Images/ProductImages/sunFlower.webp"} />
                  <div className="ProductSizes">
                    <Text
                      text={"3L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                    <Text
                      text={"5L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                  </div>
                </div>
                <div className="TabContentRight">
                  <Text
                    text={"Sunflower Oil"}
                    FontWeight={"700"}
                    Color={"#E10004"}
                    FontSize={"3.2rem"}
                    Margin={"0px 0px 25px 0px"}
                  />
                  <Text
                    FontSize={"2.5rem"}
                    text={
                      "Sunflower oil is widely recognized as one of the healthiest cooking oil available. It is abundant in vitamin E, a powerful antioxidant that helps protect cells from damage, and supports immune function. With its high-quality standards, Al-Ali sunflower oil enriches your life and safeguards your well-being."
                    }
                  />

                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "<span>Good for the Heart:</span>Packed with heart-friendly compounds, sunflower oil promotes cardiovascular well-being and contributes to overall health. The optimal balance of omega-6 and omega-3 fatty acids found in sunflower oil helps maintain healthy cholesterol levels, reducing the risk of cardiovascular diseases and promoting a healthy heart. By choosing Al-Ali sunflower oil, you can actively safeguard your heart's health."
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    text={
                      "<span>Improved gut health:</span>Sunflower oil contains naturally occurring bioactive compounds, including polyphenols. These compounds possess antioxidant and anti-inflammatory properties, contributing to overall gut health. Additionally, sunflower oil is rich in oleic acid, a beneficial fatty acid known for its positive impact on heart health and improved gut health. "
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "These bioactive compounds support a healthy digestive system, enabling you to maintain optimal well-being. Incorporating sunflower oil into your daily cooking routine offers numerous health benefits and quality of life. Al - Ali sunflower oil combines these advantages with their commitment to international technology and expertise, ensuring that every meal you prepare is a nourishing choice."
                    }
                  />
                </div>
              </div>

              <div className="SpecificationTable">
                <div className="SpecificationHead text-center">
                  <Text
                    text="cartons per full container load (FCL)"
                    TextTransform={"uppercase"}
                    FontWeight={"700"}
                    FontSize={"2.4rem"}
                    Margin={"50px 0px 50px 0px"}
                  />
                </div>
                <div className="SpecificationTableContent">
                  <table>
                    <tr>
                      <th>
                        <Text
                          text="packing"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Padding={"20px 0px 20px 40px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="carton per 20 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 0px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="cartons per 40 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 20px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                    </tr>

                    {SunFlowerOilData.slice(0, rowsToShow).map(
                      (item, index) => (
                        <tr key={index}>
                          <td>
                            <Text
                              text={item.PackingName}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 40px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer20}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer40}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </table>

                  <button
                    onClick={handleToggleRows}
                    className="TableMoreButton"
                  >
                    {buttonTextSun}
                  </button>
                </div>
              </div>
            </Tab>

            {/* Palm OIL */}

            <Tab eventKey="palmoil" title={"Palm Oil"}>
              <div className="TabsContent d-flex">
                <div className="TabContentLeft">
                  <Image Src={"./Images/ProductImages/palmOil2.webp"} />
                  <div className="ProductSizes">
                    <Text
                      text={"5L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                    <Text
                      text={"17L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                  </div>
                </div>
                <div className="TabContentRight">
                  <Text
                    text={"Palm Oil"}
                    FontWeight={"700"}
                    Color={"#E10004"}
                    FontSize={"3.2rem"}
                    Margin={"0px 0px 25px 0px"}
                  />
                  <Text
                    FontSize={"2.5rem"}
                    text={
                      "Palm oil is a versatile and nourishing cooking oil that offers numerous benefits for your overall well-being. At Al-Ali, we are committed to providing high-quality palm oil that enriches your life and supports your health."
                    }
                  />
                  <ul>
                    <li>
                      <Text
                        FontSize={"2.2rem"}
                        text={
                          "Nutritional Powerhouse: Palm oil is a rich source of essential nutrients, including vitamins A and E, as well as antioxidants. These nutrients contribute to overall wellness and support various bodily functions, including immune system health and cell protection."
                        }
                      />{" "}
                    </li>
                    <li>
                      {" "}
                      <Text
                        FontSize={"2.2rem"}
                        text={
                          "Heart Health Support: Palm oil contains a balanced ratio of saturated and unsaturated fats, making it a heart-healthy choice. Tocotrienols, a special type of vitamin E in palm oil, help keep our heart healthy by stopping cholesterol from getting damaged and making sure our blood vessels work well."
                        }
                      />
                    </li>
                    <li>
                      <Text
                        FontSize={"2.2rem"}
                        text={
                          "Immune System Boost: Palm oil's natural antioxidants help support a robust immune system."
                        }
                      />
                    </li>
                    <li>
                      <Text
                        FontSize={"2.2rem"}
                        text={
                          "Cooking Versatility: Palm oil's high smoke point and stable composition make it suitable for various cooking methods, including frying, baking, and sautéing. Its neutral flavor allows it to complement a wide range of dishes, making it a versatile choice in the kitchen."
                        }
                      />
                    </li>
                  </ul>
                  <Text
                    FontSize={"2.4rem"}
                    text={
                      "Embrace the health benefits of palm oil for a nourished body and enhanced overall wellness. Trust  Al- Ali to provide you with high-quality palm oil that enriches your life and supports your vitality. By incorporating palm oil into your cooking routine, you can enjoy its nutritional power and contribute to your overall well-being. Make the conscious choice to prioritize your health with Al-Ali palm oil."
                    }
                  />
                </div>
              </div>
              <div className="SpecificationTable">
                <div className="SpecificationHead text-center">
                  <Text
                    text="cartons per full container load (FCL)"
                    TextTransform={"uppercase"}
                    FontWeight={"700"}
                    FontSize={"2.4rem"}
                    Margin={"50px 0px 50px 0px"}
                  />
                </div>
                <div className="SpecificationTableContent">
                  <table>
                    <tr>
                      <th>
                        <Text
                          text="packing"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Padding={"20px 0px 20px 40px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="carton per 20 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 0px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="cartons per 40 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 20px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                    </tr>

                    {PalmOilData.slice(0, rowsToShowPalmOil).map(
                      (item, index) => (
                        <tr key={index}>
                          <td>
                            <Text
                              text={item.PackingName}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 40px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer20}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer40}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </table>

                  <button
                    onClick={handleToggleRows}
                    className="TableMoreButton"
                  >
                    {buttonTextPalm}
                  </button>
                </div>
              </div>
            </Tab>

            {/* Canola OIL */}

            <Tab eventKey="CanolaOil" title={"Canola Oil"}>
              <div className="TabsContent d-flex">
                <div className="TabContentLeft">
                  <Image Src={"./Images/ProductImages/canolaOil.webp"} />
                  <div className="ProductSizes">
                    <Text
                      text={"3L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                    <Text
                      text={"5L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                  </div>
                </div>
                <div className="TabContentRight">
                  <Text
                    text={"Canola Oil"}
                    FontWeight={"700"}
                    Color={"#E10004"}
                    FontSize={"3.2rem"}
                    Margin={"0px 0px 25px 0px"}
                  />
                  <Text
                    FontSize={"2.5rem"}
                    text={
                      "Canola oil is widely acknowledged as one of the most health-conscious cooking oil available today. It is a rich source of essential nutrients that can enhance your well-being while maintaining your heart's health. With Al-Ali's Canola Oil, you're embracing a premium standard of quality that actively contributes to your overall health."
                    }
                  />

                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "<span>Heart Health: </span>Canola oil is brimming with heart-healthy properties that actively support cardiovascular well-being. The ideal ratio of omega-6 to omega-3 fatty acids found in canola oil aids in maintaining optimal cholesterol levels, thereby reducing the risk of heart-related issues and promoting a robust heart. By choosing Al-Ali's Canola Oil, you are making a conscious choice to safeguard your heart's vitality."
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    text={
                      "<span>Gut Health: </span>Canola oil contains natural bioactive compounds, including vitamin K and vitamin E, which are known for their antioxidant and anti-inflammatory properties. These elements are invaluable in fostering overall gut health, contributing to a thriving digestive system. Canola oil also possesses oleic acid, a beneficial fatty acid known for its positive influence on heart health and digestive well-being. These combined properties ensure that your digestive system functions at its best, empowering you to maintain optimal vitality."
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "<span>Quality of Life:</span>Incorporating canola oil into your daily cooking routine offers a wealth of health benefits that enhance your quality of life. Al-Ali's Canola Oil combines these advantages with a commitment to the highest international standards and expertise, guaranteeing that every meal you prepare is a nourishing and wholesome choice.."
                    }
                  />
                </div>
              </div>

              <div className="SpecificationTable">
                <div className="SpecificationHead text-center">
                  <Text
                    text="CARTONS PER FULL CONTAINER LOAD (FCL)"
                    TextTransform={"uppercase"}
                    FontWeight={"700"}
                    FontSize={"2.4rem"}
                    Margin={"50px 0px 50px 0px"}
                  />
                </div>
                <div className="SpecificationTableContent">
                  <table>
                    <tr>
                      <th>
                        <Text
                          text="packing"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Padding={"20px 0px 20px 40px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="carton per 20 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 0px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                      <th>
                        <Text
                          text="cartons per 40 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 20px 20px 0px"}
                          Color={"white"}
                        />
                      </th>
                    </tr>

                    {CanolaOilData.slice(0, rowsToShowCanolaOil).map(
                      (item, index) => (
                        <tr key={index}>
                          <td>
                            <Text
                              text={item.PackingName}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 40px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer20}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                          <td>
                            <Text
                              text={item.CartonPer40}
                              TextTransform={"capitalize"}
                              FontWeight={"700"}
                              FontSize={"2rem"}
                              Margin={"20px 0px 20px 0px"}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </table>

                  <button
                    onClick={handleToggleRows}
                    className="TableMoreButton"
                  >
                    {buttonTextCanola}
                  </button>
                </div>
              </div>
            </Tab>

            {/* VEGETABLE OIL */}

            <Tab eventKey="VegetableOil" title={"Vegetable Ghee"}>
              <div className="TabsContent d-flex">
                <div className="TabContentLeft VegetableGheeProduct">
                  <Image Src={"./Images/ProductImages/alAli_vegGhee.png"}  />
                  <div className="ProductSizes">
                    <Text
                      text={"3L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                    <Text
                      text={"5L"}
                      Color={"#E10004"}
                      FontWeight={"700"}
                      FontSize={"2.2rem"}
                      BgColor={"white"}
                      Display={"inline-block"}
                      Radius={"42px"}
                    />
                  </div>
                </div>
                <div className="TabContentRight">
                  <Text
                    text={"Vegetable Ghee"}
                    FontWeight={"700"}
                    Color={"#E10004"}
                    FontSize={"3.2rem"}
                    Margin={"0px 0px 25px 0px"}
                  />
                  <Text
                    FontSize={"2.5rem"}
                    text={
                      "Vegetable ghee is a versatile and health-promoting cooking ghee that offers a range of benefits for your overall well-being. At Al-Ali, we prioritize the highest quality standards to provide you with vegetable ghee that enriches your life and supports your health."
                    }
                  />

                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "<span>Heart-Healthy Choice:  </span>The balanced composition of fatty acids, including the optimal ratio of omega-6 and omega-3, helps maintain healthy cholesterol levels, reducing the risk of cardiovascular diseases and supporting a healthy heart. By choosing Al-Ali vegetable ghee, you actively safeguard your heart's health and well-being."
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    text={
                      "<span>Supporting Digestive Wellness:  </span>Vegetable gee contains naturally occurring bioactive compounds, including polyphenols, which play a crucial role in promoting overall gut health. These possess powerful antioxidant and anti-inflammatory properties that benefit your digestive system. Additionally, vegetable ghee is rich in beneficial fatty acids like oleic acid, known for their positive impact on heart health and improved gut function. By incorporating Al-Ali vegetable ghee into your diet, you nourish your digestive system and maintain optimal well-being."
                    }
                  />
                  <Text
                    FontSize={"2.2rem"}
                    Margin={"25px 0px 25px 0px"}
                    text={
                      "<span>Enhancing Quality of Life: </span> Incorporating vegetable ghee into your daily cooking routine offers numerous health benefits and enhances your overall quality of life. Whether you're sautéing vegetables or preparing a delicious stir-fry, Al-Ali vegetable ghee ensures that every meal you prepare is a nourishing choice."
                    }
                  />
                </div>
              </div>

              <div className="SpecificationTable">
                <div className="SpecificationHead text-center">
                  <Text
                    text="CARTONS PER FULL CONTAINER LOAD (FCL)"
                    TextTransform={"uppercase"}
                    FontWeight={"700"}
                    FontSize={"2.4rem"}
                    Margin={"50px 0px 50px 0px"}
                  />
                </div>
                <div className="SpecificationTableContent Ghee">
                  <table>
                  
                        <tr>
                      <th>
                        <Text
                          text="packing"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Padding={"20px 0px 20px 40px"}
                          Color={"white"}
                        />
                      </th>
                      {/* <th>
                        <Text
                          text="carton per 20 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 0px 20px 0px"}
                          Color={"white"}
                        />
                      </th> */}
                      {/* <th>
                        <Text
                          text="cartons per 40 FCL"
                          TextTransform={"capitalize"}
                          FontWeight={"700"}
                          FontSize={"2.4rem"}
                          Margin={"20px 20px 20px 0px"}
                          Color={"white"}
                        />
                      </th> */}
                    </tr>

                    

                    {VegetableGheeData.slice(0, rowsToShowCanolaOil).map(
                      (item, index) => (
                        <tr key={index}>
                        <td>
                          <Text
                            text={item.PackingName}
                            TextTransform={"capitalize"}
                            FontWeight={"700"}
                            FontSize={"2rem"}
                            Margin={"20px 0px 20px 40px"}
                          />
                        </td>
                        <td>
                          <Text
                            text={item.CartonPer20}
                            TextTransform={"capitalize"}
                            FontWeight={"700"}
                            FontSize={"2rem"}
                            Margin={"20px 0px 20px 0px"}
                          />
                        </td>
                        <td>
                          <Text
                            text={item.CartonPer40}
                            TextTransform={"capitalize"}
                            FontWeight={"700"}
                            FontSize={"2rem"}
                            Margin={"20px 0px 20px 0px"}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>
                  <button
                    onClick={handleToggleRows}
                    className="TableMoreButton"
                  >
                    {buttonTextCanola}
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

        {/* PAGE tab bg */}

        <div className="Leave1">
          <Image Src={"./Images/ProductsPage/ProductPageLeave1.png"} />
        </div>
      </div>

      {/* PAGE BANNER */}

      <div className="PageBanner">
        <div className="PageBannerWrapper">
          <Image
            Src={"./Images/PageBanners/productpagebanner.webp"}
            Width={"100%"}
          />
        </div>
        <div className="ProductBannerBG"></div>
      </div>

      {/* WHY CHOOSE US  */}
      <div className="WhyChoseUSParent">
        <div className="WhyChoseUS">
          <h1 className="text-center">
            Choosing <span>Al-Ali</span>
          </h1>
          <div className="ChooseAlAliContent">
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/natural.png"} />
              <Text
                text={"Zero Cholesterol "}
                FontWeight={"600"}
                Margin={"20px 0px 0px 0px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/homegrown.png"} />
              <Text
                text={"Rich Vitamins"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 12px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/purity.png"} />
              <Text
                text={"Low Absorption"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 12px "}
              />
            </div>
          </div>
          <div className="WhyChooseUsParg">
            <Text
              text={
                "Opt for our cooking oil to enjoy heart-healthy, cholesterol-free meals that are rich in essential vitamins. Plus, our 'Zero Absorption' feature keeps your dishes deliciously light and flavorful, free from excess grease."
              }
            />
          </div>
        </div>
        <div className="ProductFoodItemBG">
          <Image Src={"./Images/ProductsPage/ProductPageFoodITems.webp"} />
        </div>
      </div>

      {/* FOOTER COMMITMENT */}
      <Footer />

      {/* ALL BG SECTIONS */}

      <div className="LeftTopBG"></div>
    </div>
  );
}
