import React, { useEffect, useState } from 'react'
import "./Navbar.css"
import Button from '../Button/Button'

export default function Navbar() {

  const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);
  const toggleHamburgerMenu = () => {
    setHamburgerMenuVisible(!hamburgerMenuVisible);
  }

  return (
    <div className="Navbar">
        <div className="NavbarLogo">
          <Button Src={"./Images/Navbar/Al-Ali - Logo.webp"} RouteLink={"/"} />
        </div>
        <div className="NavbarLinks">
          
          <Button
            RouteLink={"/products"}
            text="Product"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
          />
          <Button
            RouteLink={"/about_us"}
            text="About US"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
            Margin={"0px 65px 0px 65px"}
          />
          <Button
             RouteLink={"/contact_us"}
            text="contact us"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
            Margin={"0px 45px 0px 0px"}
          />
          
        </div>

        <div className='Mobile_Menu'>
        <div className="Menu-Button">
        <Button
             Src={"/Images/Navbar/menubutton.png"}
            Margin={"0px 45px 0px 0px"}
            handleClick={toggleHamburgerMenu}
          />        
          </div>

          <div className={`HamburgerMenu ${hamburgerMenuVisible ? 'visible' : ''}`}>
        <div className='MenuList'>
        <div className="NavbarLinks">
        <div className="NavbarLogo">
          <Button Src={"./Images/Navbar/Al-Ali - Logo.webp"} RouteLink={"/"} />
        </div>
          
          <Button
            RouteLink={"/products"}
            text="Product"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
            Margin={"20px 0px 20px 0px"}
          />
          <Button
            RouteLink={"/about_us"}
            text="About US"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
            Margin={"20px 0px 20px 0px"}
          />
          <Button
             RouteLink={"/contact_us"}
            text="contact us"
            TextTransform={"uppercase"}
            FontSize={"2rem"}
            FontWeight={"700"}
            Margin={"0px 45px 0px 0px"}
          />
          
        </div>
          </div>
        </div>
        </div>
      </div>
  )
}
