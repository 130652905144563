import React, { useContext } from 'react'
import { HashLink } from 'react-router-hash-link';
import Button from '../Button/Button'
import Text from '../Text/Text'
import Image from '../Image/Image'
import "./Footer.css"
import { GlobalContext } from "../../GlobalContext/Global.context";

export default function Footer() {
  const {setActiveTab} = useContext(GlobalContext)

  return (
    <div className="Footer">
      <div className="FooterWrapper">
      <div className="FooterLogo">
        <Button Src={"./Images/Footer/footerLogo.png"} />
        <div className="SocialMediaLink d-flex">
            <Button
              RouteLink={"mailto:info@alalioil.com"}
              Src={"./Images/HomePage/email.png"}
            />
            <Button
              RouteLink={"https://www.facebook.com/AlAliOil"}
              Src={"./Images/HomePage/facebook.png"}
              Margin={"0px 35px 0px 35px"}
            />
            <Button
              RouteLink={"https://www.instagram.com/alalioil"}
              Src={"./Images/HomePage/instagram.png"}
            />
          </div>
        </div>
        <div className="ContantUs">
            <Text text={"Contact Us"} FontWeight={"600"} Margin={"0px 0px 15px 0px"} />
            <div className="PhoneNumber d-flex">
                <Image Src={"./Images/Footer/phone.png"} />
                <Text text={"+971502479309"} FontSize={"1.4rem"} Margin={"0px 0px 0px 15px"} />
            </div>
            <div className="EmailAddress d-flex" >
        <Image Src={"./Images/Footer/smallemail.png"} />
        <a href="mailto:info@alalioil.com">info@alalioil.com</a>
                </div>
        <div className="LocationAddress d-flex">
        <Image Src={"./Images/Footer/location.png"} />
        <Text text={"P.O. Box: 61292, R/A # 7 Jebel Ali Free Zone, Dubai, U.A.E"} FontSize={"1.4rem"} Margin={"0px 0px 0px 15px"}/>
        </div>
        </div>
        <div className="OurProduct">
        <Text text={"Our Products"} FontWeight={"600"} Margin={"0px 0px 15px 0px"} />
        <HashLink smooth to="/products#products">
        <Button text={"Sunflower Oil"} FontSize={"1.4rem"} FontWeight={"500"} handleClick={() => {setActiveTab("sunFlowerOil")}} />
        </HashLink>
        <HashLink smooth to="/products#products">
        <Button text={"Palm Oil"} FontSize={"1.4rem"} FontWeight={"500"} Margin={"15px 0px 15px 0px"} handleClick={() => {setActiveTab("palmoil")}}/>
        </HashLink>
        <HashLink smooth to="/products#products">
        <Button text={"Canola Oil"}  FontSize={"1.4rem"} FontWeight={"500"} handleClick={() => {setActiveTab("CanolaOil")}}/>
        </HashLink>


        </div>
        <div className="WhoweAre">
        <Text text={"Who We Are"} FontWeight={"600"} Margin={"0px 0px 15px 0px"}/>
        <HashLink smooth to="/about_us#mission">
        <Button text={"Our Commitment"} Margin={"0px 0px 15px 0px"} FontSize={"1.4rem"} FontWeight={"500"} />
        </HashLink>
        {/* <Button text={"Sustainability"} FontSize={"1.4rem"} FontWeight={"500"} Margin={"15px 0px 15px 0px"}/> */}
        <Button text={"Contact Us"} RouteLink={"/contact_us"} FontSize={"1.4rem"} FontWeight={"500"}/>

        </div>
      </div>

      <div className="FooterVectorBG"></div>
    <div className="FooterLeaveBG"></div>
        
      </div>
  )
}
