import React, { useState } from "react";
import Navbar from "../../CommonComponents/Navbar/Navbar";
import Image from "../../CommonComponents/Image/Image";
import "./ContactUsPage.css";
import Text from "../../CommonComponents/Text/Text";
import Footer from "../../CommonComponents/Footer/Footer";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Helmet } from 'react-helmet';


export default function ContactUsPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // FUNCTION FOR THE CONTACT US FORM SUBMITTED TO EMAIL

  function ContactForm() {
    const [formData, setFormData] = useState({});

    function validEmail(email) {
      var re =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      return re.test(email);
    }

    function validateHuman(honeypot) {
      if (honeypot) {
        console.log("Robot Detected!");
        return true;
      } else {
        console.log("Welcome Human!");
      }
    }

    function getFormData(form) {
      var elements = form.elements;

      var fields = Object.keys(elements)
        .filter(function (k) {
          return elements[k].name !== "honeypot";
        })
        .map(function (k) {
          if (elements[k].name !== undefined) {
            return elements[k].name;
          } else if (elements[k].length > 0) {
            return elements[k].item(0).name;
          }
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos && item;
        });

      var formData = {};
      fields.forEach(function (name) {
        var element = elements[name];
        formData[name] = element.value;
        if (element.length) {
          var data = [];
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i);
            if (item.checked || item.selected) {
              data.push(item.value);
            }
          }
          formData[name] = data.join(", ");
        }
      });

      // add form-specific values into the data
      formData.formDataNameOrder = JSON.stringify(fields);
      formData.formGoogleSheetName = form.dataset.sheet || "Sheet1"; // default sheet name
      formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

      console.log(formData);
      return formData;
    }

    function handleFormSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const data = getFormData(form);

      if (data.email && !validEmail(data.email)) {
        var invalidEmail = form.querySelector(".email-invalid");
        if (invalidEmail) {
          invalidEmail.style.display = "block";
          return false;
        }
      } else {
        // disableAllButtons(form);
        setLoading(true); // Show loading animation
        var url = form.action;
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        xhr.onreadystatechange = function () {
          // console.log(xhr.status, xhr.statusText);
          // console.log(xhr.responseText);
          if (xhr.readyState === 4) {
            // Check if the request is complete
            if (xhr.status === 200) {
              setSuccess(true); // Form sent successfully
              setLoading(false); // Hide loading animation
            } else {
              // Handle any error here
              setLoading(false); // Hide loading animation
            }
          }
        };
        var encoded = Object.keys(data)
          .map(function (k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
          })
          .join("&");
        xhr.send(encoded);
      }
    }

    function disableAllButtons(form) {
      var buttons = form.querySelectorAll("button");
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
      }
    }

    return (
      <form
        className="gform pure-form pure-form-stacked"
        onSubmit={handleFormSubmit}
        data-email="alaliassets@gmail.com"
        action="https://script.google.com/macros/s/AKfycbwhH0VtqVeBh4JLyt37vNcXEpE57-WJ26wAcnH0Mr_S90TH_rvM83qxJweGsB2GcwCh/exec"
      >

        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Name"
            name="name"
            id="name"
            required
          />
          <label for="name" class="form_label_name">
            Name
          </label>
        </div>



        <div class="form__group field">
          <input
            type="email"
            class="form__field"
            placeholder="Name"
            name="email"
            id="email"
            required
          />
          <label for="email" class="form_label_email">
            Email
          </label>
        </div>

        
        <div class="form__group field">
          <input
            type="number"
            class="form__field"
            name="number"
            placeholder="Name"

            id="number"
            required
            onWheel={(e) => e.target.blur()}
          />
          <label for="number" class="form_label_phone">
            Phone Number
          </label>
        </div>

        <div class="form__group field">
          <textarea
          className="form__field"
          placeholder="messsage"
            name="message"
            cols="10"
            rows="1"
            required
          ></textarea>
          <label for="message" class="form_label_message">
            Message
          </label>
        </div>

        {loading ? (
          <div className="loading-animation">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="76"
              visible={true}
            />
          </div>
        ) : success ? (
          <div className="thankyou_message">
            <h1>Thanks for contacting us!</h1>
            <div className="close-btn">
              <button onClick={() => window.location.reload()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          <button className="submit press-effect">send</button>
        )}
      </form>
    );
  }

  return (
    <div className="ContactUsPageParent">

    {/* TITLE OF THE PAGE  */}
    <Helmet>
        <title>Contact Us</title>
      </Helmet>


      {/* NAVBAR COMPONENT */}
      <Navbar />

      {/* PAGE BANNER */}

      <div className="AboutPageBanner">
        <div className="PageBannerWrapper">
          <Image
            Src={"./Images/PageBanners/contactUsbanner.webp"}
            Width={"100%"}
          />
        </div>
        <div className="ProductBannerBG"></div>
        {/* PAGE tab bg */}

        <div className="Leave1">
          <Image Src={"./Images/ProductsPage/ProductPageLeave1.png"} />
        </div>
      </div>

      {/* WHY CHOOSE US  */}
      <div className="WhyChoseUSParent">
        <div className="WhyChoseUS">
          <h1 className="text-center">
            Contact <span>Us</span>
          </h1>
          <div className="ContactUsParg">
            <Text
              text={
                "We'd love to hear from you! Whether you have questions, feedback, or simply want to connect, our team is here to assist you. Feel free to reach out through the following channels: "
              }
            />
            <div className="CusotmerSupport">
              <Text
                Margin={"35px 0px 35px 0px"}
                text={
                  "<span>Customer Support:</span> Our dedicated customer support team is available to assist you with any inquiries or concerns. You can reach them at <span>+971502479309</span> or"
                }
              />
              <span>
                <a href="mailto:info@alalioil.com">info@alalioil.com</a>
              </span>
            </div>
            <div className="VisitUs">
            <Text
              text={
                "<span>Visit Us:</span> If you prefer a face-to-face conversation, we welcome you to visit our office at <span> P.O. Box: 61292, R/A # 7 Jebel Ali Free Zone, Dubai, U.A.E</span> Our team will be delighted to assist you in person."
              }
            />
            </div>
            
            <div className="SolialMediaContact">
              <Text
                text={
                  "<span>Social Media:</span> Connect with us on your favorite social media platforms for updates, promotions, and more. Follow us on "
                }
              />
              <div className="SocialMediaContactLink">
                <span>
                  <a href="https://www.facebook.com/AlAliOil">Facebook,</a>
                </span>
                <span>
                  <a href="https://www.instagram.com/alalioil">Instagram,</a>
                </span>
                <span>
                  <a href="">Twitter</a>
                </span>
              </div>
            </div>

            <div className="MessageUs">
              <Text
                text={
                  "<span>Message Us :</span> You can also use our convenient"
                }
              />
              <span>
                <Link href="mailto:info@al-ali.com.pk">Message Us </Link>
              </span>
              <Text text={"to send us a message directly."} />
            </div>
            <ContactForm />
          </div>
        </div>
      </div>

      {/* FOOTER COMPONENT */}
      <Footer />
    </div>
  );
}
