import React, { useContext } from "react";
import Button from "../../CommonComponents/Button/Button";
import "./HomePage.css";
import Text from "../../CommonComponents/Text/Text";
import Image from "../../CommonComponents/Image/Image";
import { Tab, Tabs } from "react-bootstrap";
import Navbar from "../../CommonComponents/Navbar/Navbar";
import Footer from "../../CommonComponents/Footer/Footer";
import { Helmet } from 'react-helmet';
import { GlobalContext } from "../../GlobalContext/Global.context";


export default function HomePage() {
  const {setActiveTab} = useContext(GlobalContext)
  return (
    <div className="HomePageParent">
      {/* TITLE OF THE PAGE  */}
      <Helmet>
        <title>Al Ali Oil</title>
      </Helmet>

      {/* NAVBAR START */}
      <Navbar />

      {/* HERO SECTION */}
      <div className="HeroSection">
        <div className="HeroLeft">
          <Text
            text={"AL-ALI COOKING OIL"}
            FontWeight={"700"}
            Margin={"15px 0px 0px 0px"}
            FontSize={"2.5rem"}
          />
          <Image
            Src={"./Images/HomePage/goodness.webp"}
            Margin={"10px 10px 10px -43px"}
          />
          <Text
            text={
              "Discover the Quality Difference of AL-ALI Cooking Oil,Elevate Every Dish to Perfection."
            }
            FontWeight={"500"}
            Padding={"0px 50px 0px 0px "}
            FontSize={"2.5rem"}
            Margin={"25px 0px 0px 0px"}
          />
          <div className="SocialMediaLink d-flex">
            <Button
               RouteLink={"mailto:info@alalioil.com"}
              Src={"./Images/HomePage/email.png"}
            />
            <Button
               RouteLink={"https://www.facebook.com/AlAliOil"}
              Src={"./Images/HomePage/facebook.png"}
              Margin={"0px 35px 0px 35px"}
            />
            <Button
              RouteLink={"https://www.instagram.com/alalioil"}
              Src={"./Images/HomePage/instagram.png"}
            />
          </div>
        </div>
        <div className="HeroRight">
          <Image Src={"./Images/HomePage/landingpageHero.webp"} />
        </div>
      </div>

      {/* WHY CHOOSE US  */}
      <div className="WhyChoseUSParent">
        <div className="WhyChoseUS">
          <h1 className="text-center">
            Why <span>Choose</span> Us?
          </h1>
          <div className="WhyChooseContent">
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/natural.png"} />
              <Text
                text={"Natural"}
                FontWeight={"600"}
                Margin={"20px 0px 0px 0px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/homegrown.png"} />
              <Text
                text={"Homegrown"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 12px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/purity.png"} />
              <Text
                text={"Purity"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 12px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/ecofriendly.png"} />
              <Text
                text={"Eco Friendly"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 0px "}
              />
            </div>
            <div className="whyChooseUsItem">
              <Image Src={"./Images/WhyChooseUS/sustainable.png"} />
              <Text
                text={"Sustainable"}
                FontWeight={"600"}
                Margin={"25px 0px 0px 0px "}
              />
            </div>
          </div>
          <div className="WhyChooseUsParg">
            <Text
              text={
                "We're your trusted choice for quality, health, and sustainability in cooking oil. With us, you get pure, nutritious oil that elevate your meals while supporting local communities and the environment."
              }
            />
          </div>
        </div>
        <div className="KaliMirchAndBG">
          <Image Src={"./Images/HomePage/kaliMirch.png"} />
        </div>
        <div className="CenterVectorBG"></div>
      </div>

      {/* OUR PRODUCT SECTION */}

      <div className="OurProductSection">
        <h2>
          Our <span>Products</span>
        </h2>
        <div className="OurProductTabs">
          <Tabs defaultActiveKey="home1" id="my-tabs">
            <Tab eventKey="home1" title={"Sunflower Oil"}>
              <div className="Mess_Inner_Tabs">
                <Button
                  Src={"./Images/ProductImages/sunFlower.webp"}
                  handleClick={() => {setActiveTab("sunFlowerOil")}}
                  RouteLink={"/products"}
                />
              </div>
            </Tab>

            <Tab eventKey="home2" title={"Palm Oil"}>
              <div className="Mess_Inner_Tabs">
                <Button
                  Src={"./Images/ProductImages/palmOil.webp"}
                  handleClick={() => {setActiveTab("palmoil")}}
                  RouteLink={"/products"}
                />
              </div>
            </Tab>

            <Tab eventKey="home3" title={"Canola Oil"}>
              <div className="Mess_Inner_Tabs">
                <Button
                  Src={"./Images/ProductImages/canolaOil.webp"}
                  handleClick={() => {setActiveTab("CanolaOil")}}

                  RouteLink={"/products"}
                />
              </div>
            </Tab>

            <Tab eventKey="home4" title={"Vegetable Ghee"}>
              <div className="Mess_Inner_Tabs">
                <Button
                  Src={"./Images/ProductImages/VegetableGhee.png"}
                  handleClick={() => {setActiveTab("VegetableOil")}}

                  RouteLink={"/products"}
                />
              </div>
            </Tab>


          </Tabs>
        </div>
      </div>

      {/* PAGE BANNER */}

      <div className="PageBanner">
        <div className="PageBannerWrapper">
          <Image
            Src={"./Images/PageBanners/homepagebanner.webp"}
            Width={"100%"}
          />
        </div>
      </div>

      {/* PAGE COMMITMENT */}
      <div className="OurCommitmentParent">
      <div className="OurCommitment">
        <h3>
          Our <span>Commitment</span>
        </h3>
        <div className="OurCommitmentParg">
          <Text
            FontSize={"2.2rem"}
            text={
              "We're your trusted choice for quality, health, and sustainability in cooking oil. With us, you get pure, nutritious oil that elevate your meals while supporting local communities and the environment."
            }
          />
        </div>
        
      </div>
      <div className="FoodItemAndBG">
        <Image Src={"./Images/HomePage/fooditem.webp"} />
      </div>
      </div>
      

      {/* FOOTER */}
      <Footer />

      {/* ALL BG SECTIONS */}

      <div className="LeftTopBG"></div>
      <div className="RightTopBG"></div>

      <div className="LeaveBG1"></div>
      <div className="LeaveImageAndBG">
        <Image Src={"./Images/HomePage/Leave2.png"} />
      </div>
      <div className="BG4"></div>
     
    </div>
  );
}
