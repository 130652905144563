import { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {     
    const [activeTab, setActiveTab] = useState("sunFlowerOil");    
    return (
        <GlobalContext.Provider value={{ 
            activeTab,
            setActiveTab
        }}>
            {children}
        </GlobalContext.Provider>
    )
};